<template>
  <div class="container page_unit">
    <van-nav-bar
      :title="unit_info.unit_name"
      left-arrow
      right-text="收藏夹"
      class="nav_bar"
      @click-left="nav_back"
      @click-right="router_unit_detail"
    />

    <img src="~@/assets/img/practice/bg2.png" class="fix_bg" />
    <div class="learning_info">
      <div>
        已学：<span>{{ unit_info.wrong_count + unit_info.right_count }}</span>
      </div>
      <div>
        正确率：<span class="sp2">{{ get_accuracy() }}</span
        >%
      </div>
    </div>
    <div class="set_list_box">
      <div
        class="set_list"
        v-for="(item, idx) in set"
        :key="idx"
        @click="router_question_card(item.id)"
      >
        <div class="set_question_count">
          <span>{{ item.count }}</span
          >题
        </div>
        <div class="set_name_box">
          {{ item.set_name }}
        </div>
        <div class="progress_box">
          <van-progress
            :percentage="get_progress(item)"
            :stroke-width="8"
            track-color="rgba(255, 244, 229, 1)"
            color="rgba(255, 187, 95, 1)"
            :show-pivot="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast, NavBar, Progress } from "vant";
import { get_set } from "@/api/practice/api";
export default {
  name: "Unit",
  data() {
    return {
      set: [],
      unit_info: {
        unit_name: "",
        wrong_count: 0,
        right_count: 0,
        all_count: 0
      }
    };
  },
  watch: {
    $router: {
      handler: "req_get_set", //调用方法
      immediate: true //进入立即执行一次
    }
  },
  methods: {
    nav_back() {
      this.$router.push({ path: "/book?ts=" + Math.random() });
    },
    req_get_set() {
      this.data_loaded = false;

      let id = this.$store.getters.unit_id
      if (isNaN(id) || id == "") {
        this.nav_back()
        return;
      }

      get_set({
        unit_id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        this.data_loaded = true;
        this.set = res.data.set;
        this.unit_info = res.data.unit_info;
        this.calc_learn_info()
      });
    },
    //计算当前已学和正确率
    calc_learn_info() {
      let right_count = 0;
      let wrong_count = 0;
      for (let i = 0; i < this.set.length; i++) {
        right_count += this.set[i].right_count;
        wrong_count += this.set[i].wrong_count;
      }
      this.unit_info.right_count = right_count;
      this.unit_info.wrong_count = wrong_count;
    },
    //跳转到词卡页
    router_question_card(id) {
      this.$store.commit("set_set_id", id);
      this.$router.push({
        path: "/question_card?ts=" + Math.random()
      });
    },
    router_unit_detail() {
      this.$router.push({ path: "/unit_detail?ts=" + Math.random() });
    },
    get_accuracy() {
      let all_count = this.unit_info.wrong_count + this.unit_info.right_count;
      if (all_count == 0) {
        return "--";
      }
      return parseInt((this.unit_info.right_count / all_count) * 100);
    },
    get_progress(set) {
      let all_count = set.wrong_count + set.right_count;
      if (all_count == 0) {
        return 0;
      }
      return parseInt((all_count / set.count) * 100);
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [Progress.name]: Progress,
    [Toast.name]: Toast
  }
};
</script>
<style lang="stylus">
.page_unit .nav_bar{
    background none
    border-bottom 0
}
.page_unit .van-nav-bar__content{
    height: 54px;
}
.page_unit .van-nav-bar__title{
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
}
.page_unit .van-hairline--bottom::after{
    border none
}
.page_unit .van-nav-bar .van-icon{
    color #fff
    font-size 18px
}
.page_unit .van-nav-bar__text{
    color #fff
    font-size 15px
}
</style>

<style lang="stylus" scoped>
.container{
    width 100%
    height 100%
    background #fff
}
.learning_info{
    position relative
    z-index 1
    display flex
    justify-content center
    margin-top 40px
}
.learning_info>div{
    color #fff
    font-size: 16px;
    text-align center
    width 50%
}
.learning_info>div>span{
    font-size: 32px;
    font-family: CenturyGothic-BoldItalic, CenturyGothic;
    font-weight bold
    font-style: italic
}
.learning_info>div>span.sp2{
    margin-right 10px
}
.set_list_box{
    position relative
    z-index 1
    background: #FFFFFF;
    border-radius: 24px 24px 0px 0px;
    box-sizing border-box
    display flex
    flex-wrap: wrap
    padding-top 50px
    margin-top 5%
    padding-bottom 30px
}
.set_list{
    width 42%
    box-sizing border-box
    height 106px
    background #fff
    padding 16px 10px
    text-align center
    box-shadow: 1px 1px 16px #efefef;
    margin-bottom 16px
    border-radius:12px;
}
.set_list&:nth-child(odd){
    margin-right:3%
    margin-left:5%
}
.set_list&:nth-child(even){
    margin-left:3%
}
.set_question_count{
  font-size 10px
}
.set_question_count span{
  font-size 24px
  margin-right 5px
  margin-left 5px
}
.set_name_box{
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #24253E;
    width 100%
    overflow hidden
}
.progress_box{
    width 60%
    margin 0 auto
    margin-top 10px
}

.fix_bg{
    width 100%
    position absolute
    top 0
    left 0
    z-index 0
}
</style>
